<template>
  <div class="wrap">
   <Loading v-show="$store.state.isLoading"/>
   <van-list v-show="!$store.state.isLoading">
      <!-- <van-cell v-for="(item,id) in list" :key="id" :title="item.title" is-link /> -->
      <van-col span="22" offset="1" v-for="(item,id) in list" :key="id" style="margin-top:10px;border-radius:5px;">
        <!-- <span class="dot"></span> -->
        <van-cell is-link :center="true" 
          v-if="item.con_type=='huizu'"
          @click="nextTo(item)"
        >
          <template #title>
            <span class="custom-title" style="font-size:12px;color:#969799;">{{item.contract_status}}</span>
          </template>
          <template #label>
            <span class="custom-title" style="font-size:14px;color:#323233;">{{item.contract_number}}</span>
            <span class="custom-title" style="font-size:12px;color:#969799;margin-top:6px">{{item.vin}}</span>
          </template>
        </van-cell>
      </van-col>
    </van-list>
  </div>
</template>
<script>
  import {rentPlanContract,getBindStatus,querytype,queryContract,getOpenId,settlemailinfof} from '@/server'
  import { Toast,Badge } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl,getUrlParam} from '@/lib/utils';
  export default {
    name: 'applyQuery',
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      accessToken:this.$store.state.accessToken,
      // list: [],
      finished: false,
      list:{},
      source:getUrlParam('flag')
      // list:[{contract_number:'信审中',vin:'OL-A000000327 '},{contract_number:'信审中',vin:'OL-A000000327 '}]
    };
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'mailing');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'mailing') {
    //     that.$router.push({ path: '/personalCenter' });
    //   }
    // }, false);
  },
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          // localStorage.setItem("openId", res.openid);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.accessToken = res.access_token;
          this.cardId = res.card_id;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/settleInfoMail' });
          }else{
            if(this.source && this.source=='1'){
              this.list = this.$store.state.dataListTotal;
            }else{
              this.getDeatail()
            }
            // if(Object.keys(this.$store.state.dataListTotal).length>0){
            //   console.log(this.$store.state.dataListTotal)
            //   this.list = this.$store.state.dataListTotal;
            // }else{
            //   console.log("a")
            //   this.getDeatail()
            // }
          }
        }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/settleInfoMail');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            // localStorage.setItem("openId", res.openid);
            setCookie('openId',res.openid)
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.accessToken = res.access_token;
            this.cardId = res.card_id;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/settleInfoMail' });
            }else{
              // if(Object.keys(this.$store.state.dataListTotal).length>0){
              //   console.log(this.$store.state.dataListTotal)
              //   this.list = this.$store.state.dataListTotal;
              // }else{
              //   console.log("b")
              //   this.getDeatail()
              // }
              if(this.source && this.source=='1'){
                this.list = this.$store.state.dataListTotal;
              }else{
                this.getDeatail()
              }
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    getDeatail(){
      sessionStorage.setItem('menu_name','结清资料邮寄-消息提醒')
      const querytypeparams = {
        card_id:this.cardId,
        function_type:'menu',
        menu_name:'结清资料邮寄-消息提醒'
      }
      querytype(querytypeparams);
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId,
        query_type:'close'
      }
      queryContract(params).then(res=>{
        if(res.status == '200'){
          if(res.cus_type=='z'){
            Dialog.alert({
              message: '您好，您的结清资料在您提供邮寄地址后7个工作日内寄出，您可以拨打4000011255进行咨询！'
            });
          }else{
            this.$store.commit('dataListTotal', res.data);
            this.list = res.data;
          }
        }else if(res.status == '502'){
          Toast.fail({
            message: '您好，您名下暂无结清合同',
            duration:'3000'
          });
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    nextTo(value){
      if(value.green_status == 'N'){
        Toast.fail({
          message: '您无纸质资料邮寄，可直接在此公众号【结清查询】获取电子版结清证明',
          duration:'3000'
        });
      }else{
        settlemailinfof({contract_number: value.contract_number}).then(res=>{
          if(res.wx_code == '200'){
            let list = res.data
            this.$store.commit('mailingListTotal', res.data);
            this.$router.push({ path: '/settleInfoMail/mailingList', query: { contractNo: value.contract_number }});
          }else if(res.wx_code == '502'){
            Toast.fail({
              message: '您的资料正在准备中，请耐心等待',
              duration:'3000'
            });
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        })
      }
    }
  },
};
</script>
<style scoped>
  .wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8;
  }
  .custom-title {
    display:block;
    /* vertical-align: middle; */
  }
  .custom-date {
    display:block;
    color: #999999;
    font-size: 12px;
  }
  .custom-status{
    font-size: 11px;
  }
  
</style>